<template>
  <div>
    <v-form>
      <p class="mb-3">
        Below is a figure that demonstrates the tautomerization of
        <stemble-latex class="no-text-transform" :content="'$\\ce{HOBt}$'" />. Which atoms of
        <stemble-latex class="no-text-transform" :content="'$\\ce{HOBt}$'" /> can attack as
        nucleophiles in the reaction?
      </p>
      <img
        :src="'/img/assignments/ChemUCI51LCP3PreQ3.png'"
        :alt="'image of tautomerization'"
        style="max-height: 150px"
      />
      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
export default {
  name: 'ChemUCI51LCP3PreQ3',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          text: 'A. $\\ce{(OH)}$',
          value: 'a',
        },
        {
          text: 'B. (Middle $\\ce{N}$)',
          value: 'b',
        },
        {
          text: 'C. ($\\ce{N}$ with plus charge)',
          value: 'c',
        },
        {
          text: 'D. ($\\ce{NH}$)',
          value: 'd',
        },
        {
          text: 'A&B',
          value: 'e',
        },
        {
          text: 'B&C',
          value: 'f',
        },
        {
          text: 'C&D',
          value: 'g',
        },
        {
          text: 'A&C',
          value: 'h',
        },
        {
          text: 'B&D',
          value: 'i',
        },
        {
          text: 'A&D',
          value: 'j',
        },
        {
          text: 'All of the above',
          value: 'k',
        },
      ],
    };
  },
};
</script>
